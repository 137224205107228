import React, { useState } from 'react';
import Cookies from 'js-cookie';
import {useNavigate} from "react-router-dom";

function LoginForm() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const navigate = useNavigate();


  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('Login with:', email, password);
    fetch(serverUrl + '/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(
          {
            "email": email,
            "password": password
          }
      )
    })
        .then(response => {
          if (!response.ok) {
            if(response.status === 401) {
              // Using alert to show the error message
              alert('Wrong email or password');
            }
            throw new Error('Network response was not ok');
          }
          return response.json(); // Assuming the server responds with the saved message including a unique ID assigned by the server
        })
        // Update chat
        .then(user => {
            console.log('Login successful.')
            sessionStorage.setItem('logged_in', 'true');
            Cookies.set('token', user.token, { expires: 90 });
            Cookies.set('user_id', user.user_id, { expires: 90 });
            Cookies.set('user_name', user.name, { expires: 90 });
            Cookies.set('user_surname', user.surname, { expires: 90 });
            Cookies.set('user_group', user.group, {expires: 90})
            navigate('/')
            window.location.reload()

            }
        )
        .catch(
            error =>
            {console.log('Login Failed.')}
        )
  };

  return (
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="email">Email:</label>
          <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
          />
        </div>
        <div>
          <label htmlFor="password">Password:</label>
          <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
          />
        </div>
        <button type="submit">Login</button>
      </form>
  );
}

export default LoginForm;
