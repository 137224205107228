import Cookies from "js-cookie";

const authFetch = (url) => {
  return fetch(url, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `${Cookies.get('token')}-${Cookies.get('user_id')}`,
    }});
}

export default authFetch;