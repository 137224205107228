import React, { useState, useEffect } from 'react';
import './Sidebar.css';
import {Link} from "react-router-dom";
import authFetch from "../functions/authFetch";
import Logout from "./Logout";
import Cookies from "js-cookie";

const serverUrl = process.env.REACT_APP_SERVER_URL;
const Sidebar = () => {
  const [openWeek, setOpenWeek] = useState(null);
  const [assignmentsByWeek, setAssignmentsByWeek] = useState({});
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };


  const toggleWeek = (week) => {
    if (openWeek === week) {
      setOpenWeek(null);
    } else {
      setOpenWeek(week);
    }
  };

  const groupChatsByWeek = (chats) => {
    const weekGroups = {};
    // Assuming each chat has a 'week' and 'id' attribute
    chats.forEach(chat => {
      const week = chat.week;
      if (!weekGroups[week]) {
        weekGroups[week] = [];
      }
      weekGroups[week].push(chat);
    });
    return weekGroups;
  };

  useEffect(() => {
    authFetch(serverUrl + '/get_assignments')
        .then(response => response.json())
        .then(data => {
          const groupedChats = groupChatsByWeek(data);
          setAssignmentsByWeek(groupedChats);
        })
        .catch(error => console.error('Error fetching chats:', error));
  }, []);

  return (
      <div>
        <button className="menu-button" onClick={toggleMobileMenu}>☰</button>
      <div className={`sidebar ${isMobileMenuOpen ? 'open' : ''}`}>
      <div style= {{
          marginTop: '50px'
        }}/>
        <Link to='/'>
          <b>Home</b>
        </Link>
        {Object.keys(assignmentsByWeek).map(week => (
            <div key={week}>
              <a href="#!" onClick={() => toggleWeek(week)}>Week {week}</a>
              {openWeek === week && (
                  <div className="dropdown">
                    {assignmentsByWeek[week].map(assignment => (
                        assignment.type === 'chat' ? (
                            <Link key={assignment.id} to={`/chat/${assignment.id}`}>
                              {assignment.title}
                            </Link>
                        ) : assignment.type === 'homework' ? (
                            <Link key={assignment.id} to={`/homework/${assignment.id}`}>
                              {assignment.title}
                            </Link>
                        ) : null  // Or handle other types if needed
                    ))}
                  </div>
              )}
            </div>
        ))}
        <div
            style={{
              color: "white",
              cursor: 'pointer',
              padding: '8px 16px', // You can adjust padding as needed
              bottom: 0,
              marginTop: 'auto',
              marginBottom: '150px',
            }}>
          <p>Logged in as {Cookies.get('user_name')} {Cookies.get('user_surname')}.</p>
          <Logout/>
        </div>

      </div>
      </div>
  );
};

export default Sidebar;
