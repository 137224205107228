import useFetch from "../functions/useFetch";
import authFetch from "../functions/authFetch";
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import Cookies from "js-cookie";
import Message from "./Message";
import './Chat.css'

const serverUrl = process.env.REACT_APP_SERVER_URL;
const Chat = () => {
  const {id} = useParams();
  const {data: chat, isPending, error} = useFetch(serverUrl + '/chats/' + id);
  const [body, setBody] = useState('');
  const [messages, setMessages] = useState();
  const [waitingForResponse, setWaitingForResponse] = useState(false);
  const [isRestarting, setIsRestarting] = useState(false);
  const navigate = useNavigate();

  // Use useEffect to update the messages state when messageList changes
  useEffect(() => {
    if (chat) {
      setMessages(chat.messages);
    }
  }, [chat]); // Only re-run the effect if m

  useEffect(() => {
    if (waitingForResponse) {
      let placeholderMessage = {type: 'agent', body: 'Generating response...', time: 0, id: 'placeholder'};
      setMessages(prevMessages => [...prevMessages, placeholderMessage])
      console.log('Waiting for response!');
      authFetch(serverUrl + '/chats/' + id + '/stream_response')
          .then(response => {
            if (!response.ok) {
              throw new Error('Network response was not ok :(')
            }
            const reader = response.body.getReader();
            const decoder = new TextDecoder();
            let completeMessage = '';

            return reader.read().then(function processText({done, value}) {
              if (done) {
                console.log("Stream complete");
                return;
              }

              let chunk = decoder.decode(value, {stream: true});
              chunk.split('\n').forEach(line => {
                if (line) {
                  completeMessage += JSON.parse(line).body;
                }
              });

              setMessages(prevMessages => {
                let updatedMessages = [...prevMessages];
                if (updatedMessages.length > 0 && updatedMessages[updatedMessages.length - 1].id === 'placeholder') {
                  updatedMessages[updatedMessages.length - 1].body = completeMessage;
                }
                return updatedMessages;
              });

              return reader.read().then(processText);
            });
          })
          .then(() => {
            console.log('Response should be visible...');
            setWaitingForResponse(false);
          })
          .catch(error => {
            console.error('Error fetching agent response...', error);
          });
    }
  }, [waitingForResponse]);

  function postMessage(messageData) {
    return fetch(serverUrl + '/chats/' + id + '/post_message', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${Cookies.get('token')}-${Cookies.get('user_id')}`,
      },
      body: JSON.stringify(messageData)
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const time = Date.now()
    let message = {type: 'user', body, time};
    setMessages(prevMessages => [...prevMessages, message])
    postMessage(message)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        // Update chat
        .then(savedMessage => {
              // setMessages(prevMessages => [...prevMessages, savedMessage]); // Append the new message to the messages state
              setMessages(prevMessages => [
                ...prevMessages.slice(0, prevMessages.length - 1),
                savedMessage
              ]);
              setBody(''); // Clear the input field
              if (savedMessage.type != 'alert') {
                setWaitingForResponse(true);
              }
            }
        )
  }

  const restartChat = () => {
    const confirmed = window.confirm('Se continui, creeremo una nuova chat vuota per questo esercizio. Puoi comunque continuare a vedere quella precedente. Vuoi continuare?');
    if (!confirmed) {
      return;
    }

    setIsRestarting(true);

    authFetch(`${serverUrl}/chats/${id}/restart_chat`)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          setIsRestarting(false);
          const newChatId = data.id;
          navigate(`/chat/${newChatId}`); // Adjust the path as per your routing setup
          window.location.reload(); // Refresh the page to start the new chat
        })
        .catch(error => {
          console.error('Error restarting the chat:', error);
          setIsRestarting(false);
        });
  };

  return (
      <div className="chat">
        {isPending && <p>Loading ...</p>}
        {error && <p>{error}</p>}
        {chat &&
            <div style={{padding: '20px'}}>
              <h2>{chat.topic}</h2>
            </div>
        }
        {
            messages && messages.map((message, index) => (
                <Message key={message.id || index} message={message}/>
            ))
        }

        <div className="message input">
          <form onSubmit={handleSubmit}>
            <textarea
                placeholder="Type a message..."
                value={body}
                onChange={(e) => setBody(e.target.value)}
                onInput={(e) => {
                  e.target.style.height = 'inherit';
                  e.target.style.height = `${e.target.scrollHeight}px`;
                }}
                onKeyDown={(e) => {
                  if ((e.metaKey || e.ctrlKey) && e.key === 'Enter' && !waitingForResponse) {
                    if (body.trim() !== '') {
                      handleSubmit(e);
                    } else {
                      e.preventDefault(); // Prevent empty submission
                    }
                  }
                }}
                style={{height: 'inherit'}}
            ></textarea>
            <button type="submit" disabled={waitingForResponse || body.trim() === ''}>
              {waitingForResponse ? '...' : '➤'}
            </button>
          </form>
        </div>


        <div style={{marginTop: '30px'}}>
          Did the tutor stop making sense?
          <button style={{marginLeft: '10px'}}
                  onClick={restartChat}
                  disabled={isRestarting}>
            {isRestarting ? 'Restarting...' : 'Restart chat'}
          </button>
        </div>
      </div>
  );
}

export default Chat;