import './App.css';
import Home from './components/Home'
import {BrowserRouter as Router, Navigate, Route, Routes} from "react-router-dom";
import LoginForm from "./components/LoginForm";
import {useEffect, useState} from "react";
import Cookies from "js-cookie";
import Homework from "./components/Homework";
import Chat from "./components/Chat";
import Sidebar from "./components/Sidebar";

function App() {

  const [authChecked, setAuthChecked] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    checkLoginStatus();
  }, []);

  const checkLoginStatus = async () => {
    const loggedInSession = sessionStorage.getItem('logged_in') === 'true';
    const userIdCookie = Cookies.get('user_id');
    const tokenCookie = Cookies.get('token');

    if (loggedInSession) {
      setIsLoggedIn(true);
      setAuthChecked(true);
    } else if (userIdCookie && tokenCookie) {
      try {
        const response = await fetch('/confirm_login', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': tokenCookie
          },
        });

        if (response.ok) {
          setIsLoggedIn(true);
          sessionStorage.setItem('logged_in', 'true');
        }
      } catch (error) {
        console.error('Login confirmation error:', error);
      } finally {
        setAuthChecked(true);
      }
    } else {
      setAuthChecked(true);
    }
  };

  if (!authChecked) {
    return <div>Loading...</div>; // Or any other loading indicator
  }

  return (
      <Router>
        <div className="App">
          {isLoggedIn && <Sidebar />}
          <div className="main">
            <Routes>
              <Route
                  exact
                  path="/"
                  element={isLoggedIn ? <Navigate to="/home" replace /> : <Navigate to="/login" replace />}
              />
              <Route path="/home" element={<Home />}/>
              <Route path="/login" element={<LoginForm />}/>
              <Route path="/chat/:id" element={<Chat />}/>
              <Route path="/homework/:id" element={<Homework />}/>
              <Route path="*" element={<h1>The address you are looking for does not exist :(</h1>}/>
            </Routes>
          </div>
        </div>
      </Router>
  );
}

export default App;